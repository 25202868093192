export const HOME_ROUTE = '/';

export const HOME_ROUTE_OBJECT = {
    key: "1",
    TitleESP: "Inicio",
    TitleENG: "Home",
    Icono: {
        name: "home (9).png",
        url: "/uploads/home_1db738aff9.png"
    }
}
