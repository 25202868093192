/* URL */
export const URL = "https://villamimosa-api.app.elingenierojefe.es";

/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_CURRENT_IDIOM = "SET_CURRENT_IDIOM";
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
/* <---- BASIC APP CONSTANTS ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* ESTABLISHMENT */
export const SET_ESTABLISHMENT_INFO = "SET_ESTABLISHMENT_INFO";
export const UPDATE_ESTABLISHMENT_INFO = "UPDATE_ESTABLISHMENT_INFO";
export const SET_ESTABLISHMENT_SETTINGS = "SET_ESTABLISHMENT_SETTINGS";
export const UPDATE_ESTABLISHMENT_SETTINGS = "UPDATE_ESTABLISHMENT_SETTINGS";
/* <---- ESTABLISHMENT ----> */

/* CHECKLISTS */
export const SET_ALL_CHECKLISTS = "SET_ALL_CHECKLISTS";
export const ADD_NEW_CHECKLIST = "ADD_NEW_CHECKLIST";
export const UPDATE_CHECKLIST = "UPDATE_CHECKLIST";
export const EDIT_SELECTED_CHECKLIST = "EDIT_SELECTED_CHECKLIST";
export const DELETE_CHECKLIST = "DELETE_CHECKLIST";
export const OPEN_CHECKLISTS_FORM = "OPEN_CHECKLISTS_FORM";
export const CLOSE_CHECKLIST_FORM = "CLOSE_CHECKLIST_FORM";
export const CHECKLIST_FORM_TOOGLE_LOADING = "CHECKLIST_FORM_TOOGLE_LOADING";
/* <---- CHECKLISTS ----> */

/* CHECKLISTS - Questions */
export const ADD_CHECKLIST_QUESTION = "ADD_CHECKLIST_QUESTION";
export const UPDATE_CHECKLIST_QUESTION = "UPDATE_CHECKLIST_QUESTION";
export const DELETE_CHECKLIST_QUESTION = "DELETE_CHECKLIST_QUESTION";
/* <---- CHECKLISTS - Questions ----> */

/* <---- CHECKLISTS - Questions Form ----> */
export const OPEN_QUESTIONS_FORM = "OPEN_QUESTIONS_FORM";
export const CLOSE_QUESTION_FORM = "CLOSE_QUESTION_FORM";
export const EDIT_SELECTED_QUESTION = "EDIT_SELECTED_QUESTION";
export const QUESTION_FORM_TOOGLE_LOADING = "QUESTION_FORM_TOOGLE_LOADING";
/* <---- CHECKLISTS - Questions Form ----> */

/* <---- CHECKLISTS - Answers Form ----> */
export const OPEN_ANSWERS_FORM = "OPEN_ANSWERS_FORM";
export const CLOSE_ANSWER_FORM = "CLOSE_ANSWER_FORM";
export const EDIT_SELECTED_ANSWER = "EDIT_SELECTED_ANSWER";
export const ANSWER_FORM_TOOGLE_LOADING = "ANSWER_FORM_TOOGLE_LOADING";
/* <---- CHECKLISTS - Answers Form ----> */

/* <---- CHECKLISTS - Shared Data ----> */
export const SET_REPORT_SHARED_DATA = "SET_REPORT_SHARED_DATA";
/* <---- CHECKLISTS - Shared Data ----> */

/* TASKS - MODAL VIEWER */
export const OPEN_TASK_IN_MODAL = "OPEN_TASK_IN_MODAL";
export const CLOSE_TASK_IN_MODAL = "CLOSE_TASK_IN_MODAL";
export const CLOSE_ALL_TASKS_IN_MODAL = "CLOSE_ALL_TASKS_IN_MODAL";
/* <---- TASKS - MODAL VIEWER ----> */

/* TASKS OPTIONS */
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_SERVICES = "SET_SERVICES";
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const SET_STATUS_SETTINGS = "SET_STATUS_SETTINGS";
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_PRIORITIES = "SET_PRIORITIES";
export const SET_DEFAULT_TASK_FORM_OPTIONS = "SET_DEFAULT_TASK_FORM_OPTIONS";
/* <---- TASKS OPTIONS ----> */

/* TASKS SEARCH */
export const SET_TASK_TEXT_SEARCH_QUERY = "SET_TASK_TEXT_SEARCH_QUERY";
export const SET_TASK_FILTER_SEARCH_QUERY = "SET_TASK_FILTER_SEARCH_QUERY";
/* <---- TASKS SEARCH ----> */

/* TASKS */
export const SET_ALL_TASKS = "SET_ALL_TASKS";
export const ADD_NEW_TASK = "ADD_NEW_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const EDIT_SELECTED_TASK = "EDIT_SELECTED_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const OPEN_TASKS_FORM = "OPEN_TASKS_FORM";
export const CLOSE_TASK_FORM = "CLOSE_TASK_FORM";
export const TASK_FORM_TOOGLE_LOADING = "TASK_FORM_TOOGLE_LOADING";
/* <---- TASKS ----> */

/* PROJECTS */
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const OPEN_PROJECTS_FORM = "OPEN_PROJECTS_FORM";
export const CLOSE_PROJECT_FORM = "CLOSE_PROJECT_FORM";
export const EDIT_SELECTED_PROJECT = "EDIT_SELECTED_PROJECT";
export const PROJECT_FORM_TOOGLE_LOADING = "PROJECT_FORM_TOOGLE_LOADING";
/* <---- PROJECTS ----> */

/* DEPARTMENTS */
export const ADD_NEW_DEPARTMENT = "ADD_NEW_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const OPEN_DEPARTMENTS_FORM = "OPEN_DEPARTMENTS_FORM";
export const CLOSE_DEPARTMENT_FORM = "CLOSE_DEPARTMENT_FORM";
export const EDIT_SELECTED_DEPARTMENT = "EDIT_SELECTED_DEPARTMENT";
export const DEPARTMENT_FORM_TOOGLE_LOADING = "DEPARTMENT_FORM_TOOGLE_LOADING";
/* <---- DEPARTMENTS ----> */

/* CATEGORIES */
export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const OPEN_CATEGORIES_FORM = "OPEN_CATEGORIES_FORM";
export const CLOSE_CATEGORIES_FORM = "CLOSE_CATEGORIES_FORM";
export const EDIT_SELECTED_CATEGORY = "EDIT_SELECTED_CATEGORY";
export const CATEGORIES_FORM_TOOGLE_LOADING = "CATEGORIES_FORM_TOOGLE_LOADING";
/* <---- CATEGORIES ----> */

/* SERVICES */
export const ADD_NEW_SERVICE = "ADD_NEW_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const OPEN_SERVICES_FORM = "OPEN_SERVICES_FORM";
export const CLOSE_SERVICES_FORM = "CLOSE_SERVICES_FORM";
export const EDIT_SELECTED_SERVICE = "EDIT_SELECTED_SERVICE";
export const SERVICES_FORM_TOOGLE_LOADING = "SERVICES_FORM_TOOGLE_LOADING";
/* <---- SERVICES ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* COLUMN SETTINGS */
export const SET_COLUMN_SETTINGS = "SET_COLUMN_SETTINGS";
/* <---- COLUMN SETTINGS ----> */

/* CALENDAR */
export const SET_ALL_ESTABLISHMENT_HOLIDAYS = "SET_ALL_ESTABLISHMENT_HOLIDAYS";
export const ADD_ESTABLISHMENT_HOLIDAY = "ADD_ESTABLISHMENT_HOLIDAY";
export const UPDATE_ESTABLISHMENT_HOLIDAY = "UPDATE_ESTABLISHMENT_HOLIDAY";
export const DELETE_ESTABLISHMENT_HOLIDAY = "DELETE_ESTABLISHMENT_HOLIDAY";
/* <---- CALENDAR ----> */

/* CALENDAR */
export const SET_ALL_RESOURCE = "SET_ALL_RESOURCE";
export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
/* <---- CALENDAR ----> */

/* COVID */
export const OPEN_COVID_FORM = "OPEN_COVID_FORM";
export const CLOSE_COVID_FORM = "CLOSE_COVID_FORM";
export const EDIT_SELECTED_COVID = "EDIT_SELECTED_COVID";
export const COVID_FORM_TOOGLE_LOADING = "COVID_FORM_TOOGLE_LOADING";
/* <---- COVID ----> */

/* COUNTRIES */
export const SET_COUNTRIES = "SET_COUNTRIES";
/* <---- COUNTRIES ----> */
